import React, { FC, memo, useEffect, useRef, useState } from 'react';
import styles from './ReviewTradeResult.module.scss';
import classNames from 'classnames';
import { TradeResult } from '../../../declaration/Review';
import { map, get, size, chain, slice } from 'lodash';
import { toLocaleString } from '../../../cores/toLocaleString';
import InspectionImageLoader from '../../InspectionImageLoader/InspectionImageLoader';
import { useInView } from 'react-intersection-observer';

interface Props {
  carNumber: string;
  tradeResult: TradeResult;
  selectedBidPrice: number;
}

const ReviewTradeResult: FC<Props> = memo(({ carNumber, tradeResult, selectedBidPrice }) => {
  const inspectionImagesRef = useRef<HTMLDivElement | null>(null);
  const [inspectionImageWidth, setInspectionImageWidth] = useState(0);
  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  const reductionReview = get(tradeResult, 'reduction_review');
  const status = get(reductionReview, 'status');

  const isStatusOngoing = status === 'ongoing';

  const inspectionResult = get(tradeResult, 'inspection_result');
  const inspectionImages = get(tradeResult, 'inspection_images');
  const reductions = get(tradeResult, 'reductions');
  const tradedPrice = get(tradeResult, 'traded_price');

  const noReduction = tradedPrice === selectedBidPrice;

  const hasReductions = size(reductions) > 0;
  const hasInspectionImages = size(inspectionImages) > 0;

  useEffect(() => {
    if (!inspectionImagesRef.current) {
      return;
    }

    setInspectionImageWidth((inspectionImagesRef.current.offsetWidth + 7) / 5 - 7);
  }, []);

  const isOverInspectionImages = inspectionImages.length > 10;
  const isOverReductions = reductions.length > 5;
  const leftInspectionImageCount = inspectionImages.length - 10;

  const diffPrice = tradedPrice - selectedBidPrice;

  return (
    <div className={styles.reviewTradeResult}>
      <div className={styles.field}>
        <span className={styles.label}>입찰가</span>
        <span className={styles.value}>{toLocaleString(selectedBidPrice) || '-'}만원</span>
      </div>
      <div className={classNames(styles.field, styles.last, noReduction && styles.noReduction)}>
        <span className={styles.label}>매입가</span>
        <span className={styles.value}>{toLocaleString(tradedPrice) || '-'}만원</span>
      </div>
      {!noReduction && <h1 className={styles.title}>가격조정 이유</h1>}
      <div className={styles.content}>
        {isStatusOngoing ? (
          <div className={styles.normalText}>{carNumber} 차량 거래내용을 헤이딜러 감가심사센터에서 꼼꼼히 검토중입니다.</div>
        ) : noReduction ? (
          <div className={styles.normalText}>기재해주신 내용과 동일하여, 감가없이 거래되었습니다.</div>
        ) : (
          <React.Fragment>
            {hasInspectionImages && (
              <div ref={ref}>
                <div ref={inspectionImagesRef} className={styles.inspectionImages}>
                  {map(slice(inspectionImages, 0, 10), (image, key) => {
                    const id = get(image, 'id');
                    const url = get(image, 'url');
                    const isLast = key === 9;

                    return (
                      <div key={id} className={styles.inspectionImageWrapper}>
                        <InspectionImageLoader
                          className={styles.inspectionImage}
                          inView={inView}
                          style={{
                            width: inspectionImageWidth,
                            height: inspectionImageWidth,
                            marginRight: (key + 1) % 5 === 0 ? 0 : 7,
                          }}
                          url={`http://image.heydealer.com/unsafe/300x300/${url}`}
                        />
                        {isLast && isOverInspectionImages && (
                          <div className={styles.count} style={{ width: inspectionImageWidth, height: inspectionImageWidth }}>
                            +{leftInspectionImageCount}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {hasReductions ? (
              <div className={styles.reductions}>
                {map(reductions, (reduction, key) => {
                  const content = get(reduction, 'content');
                  const price = get(reduction, 'price');
                  return (
                    <div key={key} className={styles.reduction}>
                      <div className={styles.text} dangerouslySetInnerHTML={{ __html: content }} />
                      <div className={styles.price}>-{toLocaleString(price)}만원</div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className={classNames(styles.normalText, styles.old)}>
                <div className={styles.normalReductions}>
                  {chain(inspectionResult)
                    .split('\n')
                    .map((i, key) => {
                      return (
                        <div key={key} className={styles.normalReduction}>
                          <span>{i}</span>
                        </div>
                      );
                    })
                    .value()}
                </div>
                <div className={styles.normalReductionPrice}>
                  <span>{diffPrice > 0 ? '감가없음' : `${toLocaleString(diffPrice)}만원`}</span>
                </div>
              </div>
            )}
            {hasReductions && status === 'completed' && (
              <React.Fragment>
                <div className={styles.hr} />
                <div className={styles.screening}>
                  <span className={styles.name}>헤이딜러 감가심사센터</span>
                  {isOverReductions ? (
                    <span className={classNames(styles.status, styles.overReductions)}>추가 증빙자료 확인 중</span>
                  ) : (
                    <span className={styles.status}>검토 완료</span>
                  )}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
});

export default ReviewTradeResult;
