import produce from 'immer';
import { Car } from '../declaration/Car';
import { action, PayloadAction } from 'typesafe-actions';
import { uniqBy } from 'lodash';
import { Pagination } from '../declaration/Pagination';
import { handleActions } from 'redux-actions';
import { createAsyncSagaReducerMap } from '../cores/createAsyncSagaReducerMap';
import axios, { AxiosResponse } from 'axios';
import createAsyncSagaAction from '../cores/createAsyncSagaAction';

export enum CarsTypes {
  getCar = '@cars/getCar',
  getCars = '@cars/getCars',
  truncateCar = '@cars/truncateCar',
  truncateCars = '@cars/truncateCars',
}

export interface CarsState {
  stopPagination: boolean;
  car: Car | null;
  cars: Array<Car>
  count: number | null,
}

export const CarsActions = {
  getCar: createAsyncSagaAction(
    CarsTypes.getCar,
    (hashId: string) => {
      return axios.get(`/admin/customer_view/cars/${hashId}/`);
    }
  ),
  getCars: createAsyncSagaAction(
    CarsTypes.getCars,
    (query: URLSearchParams) => {
      query.set('status', 'ended');
      query.set('envelope', 'true');
      return axios.get(`/admin/customer_view/cars/`, { params: query });
    }
  ),
  truncateCar: () => action(CarsTypes.truncateCar),
  truncateCars: () => action(CarsTypes.truncateCars)
};

const initialState: CarsState = {
  stopPagination: false,
  car: null,
  cars: [],
  count: null,
};

export default handleActions<CarsState, any>(
  {
    [CarsTypes.truncateCar]: (state) => {
      return produce(state, draft => {
        draft.car = null;
      })
    },
    [CarsTypes.truncateCars]: (state) => {
      return produce(state, draft => {
        draft.cars = [];
        draft.count = null;
        draft.stopPagination = false;
      })
    },
    ...createAsyncSagaReducerMap(CarsTypes.getCar, {
      onSuccess: (
        state,
        { payload
        }: PayloadAction<string, AxiosResponse<Car>>
      ) => {
        return produce(state, draft => {
          draft.car = payload.data;
        });
      }
    }),
    ...createAsyncSagaReducerMap(CarsTypes.getCars, {
      onSuccess: (
        state,
        {
          payload: {
            data: {
              meta: { next, count },
              results
            }
          }
        }: PayloadAction<string, AxiosResponse<Pagination<Car>>>
      ) => {
        return produce(state, draft => {
          draft.stopPagination = next === null;
          draft.count = count;
          draft.cars = uniqBy([...state.cars, ...results], ({ hash_id }) => hash_id);
        });
      }
    }),
  },
  initialState
);
