import React, { FC } from 'react';
import styles from './CarsItem.module.scss';
import Ink from 'react-ink';
import { Car } from '../../../declaration/Car';
import { get } from 'lodash';
import { toLocaleString } from '../../../cores/toLocaleString';
import { useHistory } from 'react-router';

interface Props {
  car: Car;
}

const CarsItem: FC<Props> = ({ car }) => {
  const history = useHistory();

  const detail = get(car, 'detail');
  const auction = get(car, 'auction');

  const modelName = get(detail, 'model_part_name');
  const inputName = get(detail, 'input_name');
  const year = get(detail, 'year');

  const bidsCount = get(auction, 'bids_count');
  const highestBid = get(auction, 'highest_bid')
  const highestBidPrice = get(highestBid, 'price')

  const mainImageUrl = get(detail, 'main_image_url');
  const hashId = get(car, 'hash_id');

  return (
    <div className={styles.carsItem} onClick={() => {
      history.push(`?hash_id=${hashId}`);
    }}>
      <div className={styles.profile}>
        <div className={styles.thumbnail} style={{ background: mainImageUrl ? `url(http://image.heydealer.com/unsafe/200x200/${mainImageUrl})` : '' }} />
      </div>
      <div className={styles.information}>
        <p className={styles.name}>
          <span className={styles.model}>{modelName || inputName || '-'}</span>
          <span className={styles.year}>{year || '-'}</span>
        </p>
        <p className={styles.field}>견적수 {toLocaleString(bidsCount) || '-'}개</p>
        <p className={styles.field}>최고가 {toLocaleString(highestBidPrice) || '-'}만원</p>
      </div>
      <Ink />
    </div>
  );
};

export default CarsItem;
