import React, { FC, useCallback, useEffect, useState } from 'react';
import styles from './Layer.module.scss';
import { Portal } from 'react-portal';
import { animated, useSpring } from 'react-spring';
import { useHistory } from 'react-router';
import classNames from 'classnames';

interface Props {
  id?: string;
  value: string | null;
  white?: boolean;
  overview?: boolean;
  closeButton?: boolean;
  onClose: () => void;
}

const Layer: FC<Props> = ({ id, value, children, white, overview, closeButton, onClose }) => {
  const isVisible = value !== null;
  const [displayed, setDisplayed] = useState(isVisible);

  const onRest = useCallback(() => {
    if (!isVisible) {
      setDisplayed(false);
    }
  }, [isVisible]);

  useEffect(() => {
    if (isVisible && !displayed) {
      setDisplayed(true);
    }
  }, [displayed, isVisible]);

  const spring = useSpring({ x: isVisible ? 0 : 100, config: { mass: 1, tension: 300, friction: 35 }, onRest });

  if (!displayed) {
    return null;
  }

  return (
    <Portal>
      <animated.div
        id={id}
        className={classNames(
          styles.layer,
          white && styles.white,
          overview && styles.overview,
          closeButton && styles.closeButton,
        )}
        style={{ transform: spring.x.interpolate((x: number) => `translateX(${x}%)`) }}
      >
        <Portal>
          <animated.div
            className={classNames(
              styles.header,
              white && styles.white,
              overview && styles.overview,
              closeButton && styles.closeButton,
              )}
            style={{ transform: spring.x.interpolate((x: number) => `translateX(${x}%)`) }}
          >
            <button
              className={styles.back}
              onClick={() => {
                onClose();
              }}
            />
          </animated.div>
        </Portal>
        <div className={styles.content}>{children}</div>
      </animated.div>
    </Portal>
  );
};

export default Layer;
