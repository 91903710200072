import React, { FC } from 'react';
import styles from './BidsItem.module.scss';
import Ink from 'react-ink';
import { get } from 'lodash';
import { toLocaleString } from '../../cores/toLocaleString';
import { useQuery } from '../../hooks/useQuery';
import { useHistory } from 'react-router';

interface Props {
  bid: any;
}

const BidsItem: FC<Props> = ({ bid }) => {
  const query = useQuery();
  const history = useHistory();
  const userHashId = get(bid, 'user_hash_id');
  const price = get(bid, 'price');
  const fullName = get(bid, 'full_name');
  const reviewsCount = get(bid, 'reviews_count');
  const ratings = get(bid, 'ratings');
  const ratingsIncludeNoInput = get(bid, 'ratings_include_no_input');
  const profileImageUrl = get(bid, 'profile_image_url');

  return (
    <div
      className={styles.bidsItem}
      onClick={() => {
        query.set('dealer_hash_id', userHashId);
        history.push(`?${query.toString()}`);
      }}
    >
      <div className={styles.header}>
        <span className={styles.price}>{toLocaleString(price) || '-'}만원</span>
        <button className={styles.requestSelling}>
          판매요청
          <Ink />
        </button>
      </div>
      <div className={styles.profile}>
        <div
          className={styles.thumbnail}
          style={{ background: `url(http://image.heydealer.com/unsafe/100x100/${profileImageUrl})` }}
        />
        <div>
          <p className={styles.dealerName}>{fullName}</p>
          <p className={styles.rating}>
            {ratingsIncludeNoInput || ''} ({toLocaleString(reviewsCount) || '-'}후기)
          </p>
        </div>
      </div>
      <Ink />
    </div>
  );
};

export default BidsItem;
