import React, { FC, useEffect, useState } from 'react';
import styles from './DealerProfile.module.scss';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { DealerActions } from '../../store/dealer';
import { get, find } from 'lodash';
import classNames from 'classnames';
import Loading from '../Loading/Loading';
import Ink from 'react-ink';
import { Portal } from 'react-portal';
import DealerReviews from '../DealerReviews/DealerReviews';
import StarRating from 'react-svg-star-rating';
import { animated, useSpring } from 'react-spring';
import { toLocaleString } from '../../cores/toLocaleString';

interface Props {
  hashId: string | null;
  carHashId: string | null;
  isVisibleSellingButton: boolean;
}

const DealerProfile: FC<Props> = ({ hashId, carHashId, isVisibleSellingButton }) => {
  const dispatch = useDispatch();
  const [toggleSellingButton, setToggleSellingButton] = useState(false);
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    if (!hashId || !carHashId) {
      return;
    }

    const query = new URLSearchParams();
    query.set('car', carHashId);

    dispatch(DealerActions.truncateDealerProfile());
    dispatch(DealerActions.getDealerProfile(hashId, query));
  }, [hashId, carHashId, dispatch]);

  const { dealerProfile, car } = useTypedSelector(({ dealer: { dealerProfile }, cars: { car } }) => ({ dealerProfile, car }));

  const sellingButtonSpring = useSpring({
    x: isMounted ? (isVisibleSellingButton ? 0 : 200) : 200,
    height: toggleSellingButton ? 276 : 76,
  }) as any;

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!dealerProfile) {
    return <Loading className={styles.loading} />;
  }

  const basicInformation = get(dealerProfile, 'basic_information');
  const reductionReview = get(basicInformation, 'reduction_review');
  const profileImageUrl = get(basicInformation, 'profile_image_url');
  const officeDisplay = get(basicInformation, 'office_display');
  const introduction = get(basicInformation, 'introduction');
  const fullName = get(basicInformation, 'full_name');
  const dealerBackgroundImageUrl = get(basicInformation, 'background_image_url');

  const manager = get(reductionReview, 'manager');
  const managerBackgroundImageUrl = get(manager, 'background_image_url');
  const managerName = get(manager, 'name');

  const reviews = get(dealerProfile, 'reviews');
  const reviewsCount = get(reviews, 'reviews_count');
  const averageRating = get(reviews, 'average_rating');

  const auction = get(car, 'auction');
  const bids = get(auction, 'bids');
  const selectedBid = find(bids, (bid) => bid.user_hash_id === hashId);
  const price = get(selectedBid, 'price');

  return (
    <div className={styles.dealerProfile}>
      <div className={styles.profile}>
        <div className={styles.background} style={{ background: `url(${dealerBackgroundImageUrl}) no-repeat center` }} />
        <div className={styles.thumbnail} style={{ backgroundImage: `url(http://image.heydealer.com/unsafe/300x300/${profileImageUrl})` }} />
      </div>
      <div className={styles.information}>
        <div>
          <h1 className={styles.greeting}>
            안녕하세요.
            <br />
            {fullName}입니다.
          </h1>
          <p className={classNames(styles.field, styles.location)}>{officeDisplay}</p>
          <p className={classNames(styles.field, styles.speech)}>48시간 내 명의이전</p>
          <p className={styles.introduction}>{introduction}</p>
        </div>
        <p className={styles.heydealerScreeningService}>헤이딜러가 모든 거래결과를 검토합니다.</p>
        <a href="https://page.heydealer.com/reduction" target="_blank" className={styles.screening}>
          <p className={styles.title}>감가심사센터 &gt;</p>
          <div className={styles.description}>
            <span className={styles.managerName}>헤이딜러 {managerName}</span>가 검토 후,
            <br />
            부당한 감가는 돌려드립니다.
          </div>
          <div className={styles.image} style={{ background: `url(${managerBackgroundImageUrl}) no-repeat center` }} />
          <Ink />
        </a>
      </div>
      <div className={styles.hr} />
      <div className={styles.reviews}>
        <div className={styles.header}>
          <div className={styles.left}>
            <h1>
              고객후기 <span className={styles.highlight}>{reviewsCount}</span>개
            </h1>
            <StarRating
              initialRating={Math.round(averageRating) / 2}
              isReadOnly
              isHalfRating
              activeColor="#396eff"
              size={14}
              roundedCorner={false}
              starClassName={styles.star}
            />
          </div>
          <div className={styles.rating}>{averageRating ? averageRating.toFixed(1) : '-'}</div>
        </div>
        {hashId && <DealerReviews hashId={hashId} />}
      </div>
      <Portal>
        <animated.div
          className={styles.sellingThisDealerButton}
          style={{
            transform: sellingButtonSpring.x.interpolate((x: number) => `translateX(${x}%)`),
            height: sellingButtonSpring.height,
          }}
          onClick={() => {
            setToggleSellingButton((prevState) => !prevState);
          }}
        >
          <div className={classNames(styles.content, styles.preview, !toggleSellingButton && styles.isVisible)}>
            <h1>이 딜러에게 판매할게요!</h1>
            <p>본사 체크 후, 딜러와 연락처가 교환됩니다</p>
          </div>
          <div className={classNames(styles.content, styles.after, toggleSellingButton && styles.isVisible)}>
            <h1>
              {fullName}님에게
              <br />
              <u>{toLocaleString(price) || '-'}만원</u>에<br />
              판매요청 하시겠어요?
            </h1>
            <p>
              <span>·</span>
              <span>
                사정이 생기거나 가격이 조율되어 거래를 취소
                <br />
                하셔도, 비용이 발생하지 않습니다.
              </span>
            </p>
            <button className={styles.button}>네, 판매요청 할게요!</button>
          </div>
          <div className={classNames(styles.direction, toggleSellingButton && styles.isActivated)} />
        </animated.div>
      </Portal>
    </div>
  );
};

export default DealerProfile;
