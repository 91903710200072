import React, { FC, memo, useEffect, useState } from 'react';
import styles from './DealerReviews.module.scss';
import { useDispatch } from 'react-redux';
import { usePrevious } from '../../hooks/usePrevious';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { get, map, slice } from 'lodash';
import { usePagination } from '../../hooks/usePagination';
import { DealerActions, DealerTypes } from '../../store/dealer';
import axios, { CancelTokenSource } from 'axios';
import DealerReviewsItem from './DealerReviewsItem/DealerReviewsItem';
import Loading from '../Loading/Loading';

interface Props {
  hashId: string;
}

let cancelToken: CancelTokenSource | null = null;

const DealerReviews: FC<Props> = memo(({ hashId }) => {
  const [page, setPage] = useState(1);
  const [isMounted, setMounted] = useState(false);
  const dispatch = useDispatch();
  const prevPage = usePrevious(page);
  const { stopPagination, dealerReviews, dealerPhotoReviews, dealerPhotoReviewsCount, isLoading } = useTypedSelector(
    ({ dealer: { stopPagination, dealerReviews, dealerPhotoReviews, dealerPhotoReviewsCount }, loading: { asyncMap } }) => {
      return {
        stopPagination,
        dealerReviews,
        dealerPhotoReviews,
        dealerPhotoReviewsCount,
        isLoading: get(asyncMap, DealerTypes.getDealerReviews) > 0,
      };
    }
  );

  useEffect(() => {
    dispatch(DealerActions.getDealerPhotoReviews(hashId));
  }, [hashId]);

  useEffect(() => {
    cancelToken = axios.CancelToken.source();
    const query = new URLSearchParams();
    query.set('page', page.toString());
    dispatch(DealerActions.getDealerReviews(hashId, query));
  }, [hashId, page]);

  useEffect(() => {
    if (prevPage === 1) {
      cancelToken = axios.CancelToken.source();
      const query = new URLSearchParams();
      query.set('page', '1');
      dispatch(DealerActions.getDealerReviews(hashId, query));
    }

    setPage(1);
    dispatch(DealerActions.truncateDealerReviews());

    return () => {
      if (cancelToken !== null) {
        cancelToken.cancel();
      }
    };
  }, [hashId]);

  useEffect(() => {
    setMounted(true);

    return () => {
      if (cancelToken !== null) {
        cancelToken.cancel();
      }

      setMounted(false);
      dispatch(DealerActions.truncateDealerReviews());
    };
  }, []);

  usePagination(() => {
    if (isLoading || stopPagination || !isMounted) {
      return;
    }

    setPage((prevPage) => prevPage + 1);
  }, document.getElementById('dealer-profile')!);

  const photoReviewSize = (window.innerWidth - 48) * 0.179487179;
  const photoReviewMargin = (window.innerWidth - 48) * 0.025641026;

  return (
    <div className={styles.dealerReviews}>
      <div className={styles.dealerPhotoReviews}>
        {map(slice(dealerPhotoReviews, 0, 5), (photo, key) => {
          const imageUrl = get(photo, 'image_url');
          const isLast = key === 4;
          return (
            <div
              className={styles.dealerPhotoReview}
              key={key}
              style={{
                width: photoReviewSize,
                height: photoReviewSize,
                marginRight: isLast ? 0 : photoReviewMargin,
                background: `url(http://image.heydealer.com/unsafe/100x100/${imageUrl})`,
              }}
            >
              {(isLast && typeof dealerPhotoReviewsCount === 'number' && dealerPhotoReviewsCount - 4 >= 1) && (
                <div className={styles.count}>
                  +{dealerPhotoReviewsCount - 4}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.hr} />
      <div className={styles.reviews}>
        {map(dealerReviews, (review) => {
          return <DealerReviewsItem key={review.hash_id} review={review} />;
        })}
      </div>
      {isLoading && <Loading className={styles.loading} />}
    </div>
  );
});

export default DealerReviews;
