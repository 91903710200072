import React, { FC, memo, useEffect, useState } from 'react';
import styles from './Timer.module.scss';
import { DateTime } from 'luxon';

interface Props {
  endAt: string;
}

const Timer: FC<Props> = memo(({ endAt }) => {
  const endAtDateTime = endAt === null ? DateTime.local() : DateTime.fromFormat(endAt, 'yyyy-MM-dd HH:mm:ss');
  const [millisecond, setMilliseconds] = useState(endAtDateTime.diffNow().milliseconds);

  useEffect(() => {
    const interval = setInterval(() => {
      const { milliseconds } = endAtDateTime.diffNow();
      setMilliseconds(milliseconds);
    }, 500);

    return () => {
      clearTimeout(interval);
    };
  }, [endAtDateTime]);

  const totalSeconds = millisecond * 0.001;

  let seconds: number | string = Math.floor(totalSeconds % 60);
  let minutes: number | string = Math.floor((totalSeconds / 60) % 60);
  let hours: number | string = Math.floor(totalSeconds / 3600);

  if (seconds < 0) seconds = 0;
  if (minutes < 0) minutes = 0;
  if (hours < 0) hours = 0;

  seconds = seconds.toString();
  minutes = minutes.toString();
  hours = hours.toString();

  if (seconds.toString().length <= 1) seconds = `0${seconds}`;
  if (minutes.toString().length <= 1) minutes = `0${minutes}`;
  if (hours.toString().length <= 1) hours = `0${hours}`;

  return (
    <span className={styles.timer}>{`${hours}시간 ${minutes}분 ${seconds}초 남음`}</span>
  );
});

export default Timer;
