import React, { FC, memo } from 'react';
import styles from './DealerReviewsItem.module.scss';
import { Review } from '../../../declaration/Review';
import { get } from 'lodash';
import StarRating from 'react-svg-star-rating';
import ReviewTradeResult from '../ReviewTradeResult/ReviewTradeResult';
import InspectionImageLoader from '../../InspectionImageLoader/InspectionImageLoader';
import { useInView } from 'react-intersection-observer';

interface Props {
  review: Review;
}

const DealerReviewsItem: FC<Props> = memo(({ review }) => {
  const [ref, inView, entry] = useInView({
    threshold: 0.5,
  })
  const car = get(review, 'car');
  const trade = get(car, 'trade');
  const tradeResult = get(trade, 'result');
  const detail = get(car, 'detail');
  const modelPartName = get(detail, 'model_part_name');
  const mainImageUrl = get(detail, 'main_image_url');
  const rating = get(review, 'rating')
  const content = get(review, 'content')
  const imageUrl = get(review, 'image_url')
  const reviewReply = get(review, 'review_reply')
  const authorDisplay = get(reviewReply, 'author_display')
  const authorImageUrl = get(reviewReply, 'author_image_url')
  const inputtedAtDisplay = get(review, 'inputted_at_display')
  const auction = get(car, 'auction');
  const selectedBid = get(auction, 'selected_bid');
  const selectedBidPrice = get(selectedBid, 'price');
  const carNumber = get(detail, 'car_number');

  return (
    <div ref={ref} className={styles.dealerReviewsItem}>
      <div className={styles.review}>
        <div className={styles.header}>
          <InspectionImageLoader
            className={styles.thumbnail}
            inView={inView}
            type="car"
            url={`http://image.heydealer.com/unsafe/100x100/${mainImageUrl}`}
          />
          <div className={styles.information}>
            <p className={styles.carName}>{modelPartName} 판매고객</p>
            <div className={styles.body}>
              <StarRating
                initialRating={rating / 2}
                isReadOnly
                isHalfRating
                activeColor="#396eff"
                size={9}
                roundedCorner={false}
                starClassName={styles.star}
                containerClassName={styles.stars}
              />
              <span className={styles.inputtedAt}>{inputtedAtDisplay}</span>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          {content}
        </div>
        {imageUrl && <img className={styles.reviewImage} alt="리뷰 이미지" src={imageUrl} width="160" />}
        {tradeResult && <ReviewTradeResult carNumber={carNumber} tradeResult={tradeResult} selectedBidPrice={selectedBidPrice} />}
      </div>
      {reviewReply && (
        <div className={styles.reply}>
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.thumbnail} style={{ background: `url(${authorImageUrl})` }} />
              <div className={styles.name}>
                {authorDisplay}
              </div>
            </div>
            <div className={styles.text}>
              {get(reviewReply, 'content')}
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

export default DealerReviewsItem;
