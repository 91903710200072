import React, { FC, memo, useEffect } from 'react';
import styles from './CarsDetail.module.scss';
import { useDispatch } from 'react-redux';
import { CarsActions } from '../../store/cars';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import Timer from '../Timer/Timer';
import Loading from '../Loading/Loading';
import { map, get } from 'lodash';
import BidsItem from '../BidsItem/BidsItem';

interface Props {
  hashId: string | null;
}

const CarsDetail: FC<Props> = memo(({ hashId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!hashId) {
      return;
    }

    dispatch(CarsActions.truncateCar());
    dispatch(CarsActions.getCar(hashId));
  }, [hashId, dispatch]);

  const car = useTypedSelector(({ cars: { car } }) => car);

  if (!car) {
    return <Loading />;
  }

  const auction = get(car, 'auction');
  const statusMessage = get(auction, 'status_message');
  const bids = get(auction, 'bids');
  const endAt = get(auction, 'end_at');

  return (
    <div className={styles.carsDetail}>
      <div className={styles.title} dangerouslySetInnerHTML={{ __html: statusMessage }} />
      <div className={styles.timer}>
        <Timer endAt={endAt} />
      </div>
      <div className={styles.bids}>
        {map(bids, (bid) => {
          return <BidsItem key={get(bid, 'hash_id')} bid={bid} />;
        })}
      </div>
    </div>
  );
});

export default CarsDetail;
