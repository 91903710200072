import React, { FC, memo, useEffect, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';
import styles from './Cars.module.scss';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { usePrevious } from '../../hooks/usePrevious';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { CarsActions, CarsTypes } from '../../store/cars';
import { usePagination } from '../../hooks/usePagination';
import { get, map } from 'lodash';
import CarsItem from './CarsItem/CarsItem';
import Loading from '../Loading/Loading';
import { useQuery } from '../../hooks/useQuery';
import Layer from '../Layer/Layer';
import CarsDetail from '../CarsDetail/CarsDetail';
import DealerProfile from '../DealerProfile/DealerProfile';
import { toLocaleString } from '../../cores/toLocaleString';

interface Props {

}

let cancelToken: CancelTokenSource | null = null;

const Cars: FC<Props> = memo(() => {
  const [page, setPage] = useState(1);
  const [scrollY, setScrollY] = useState(0);
  const [scrollLock, setScrollLock] = useState(false);
  const [isMounted, setMounted] = useState(false);
  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const prevPage = usePrevious(page);
  const { stopPagination, cars, count, isLoading } = useTypedSelector(({ cars: { stopPagination, cars, count }, loading: { asyncMap } }) => {
    return {
      stopPagination,
      cars,
      count,
      isLoading: get(asyncMap, CarsTypes.getCars) > 0
    };
  });

  const hashId = query.get('hash_id');
  const dealerHashId = query.get('dealer_hash_id');

  useEffect(() => {
    cancelToken = axios.CancelToken.source();
    const query = new URLSearchParams();
    query.set('page', page.toString());
    dispatch(CarsActions.getCars(query));
  }, [page]);

  useEffect(() => {
    if (prevPage === 1) {
      cancelToken = axios.CancelToken.source();
      const query = new URLSearchParams();
      query.set('page', '1');
      dispatch(CarsActions.getCars(query));
    }

    setPage(1);
    dispatch(CarsActions.truncateCars());

    return () => {
      if (cancelToken !== null) {
        cancelToken.cancel();
      }
    };
  }, []);

  useEffect(() => {
    setMounted(true);

    return () => {
      if (cancelToken !== null) {
        cancelToken.cancel();
      }

      setMounted(false);
      dispatch(CarsActions.truncateCars());
    };
  }, []);

  usePagination(() => {
    if (isLoading || stopPagination || hashId || dealerHashId || !isMounted) {
      return;
    }

    setPage(prevPage => prevPage + 1);
  });

  const hasLayer = hashId || dealerHashId;

  useEffect(() => {
    if (hasLayer) {
      setScrollY(window.scrollY);
      setScrollLock(true)
    } else {
      setScrollY(0)
      setScrollLock(false)
    }
  }, [hasLayer])

  return (
    <div className={styles.cars} style={{ overflow: scrollLock ? 'hidden' : 'visible', height: scrollLock ? '100vh' : 'auto' }}>
      <div className={styles.header}>
        <span className={styles.label}>선택대기중</span>
        <span className={styles.count}>{count ? toLocaleString(count) : '-'}대</span>
      </div>
      <div className={styles.items} style={{ transform: `translateY(-${scrollY}px)` }}>
        {map(cars, car => {
          return <CarsItem key={car.hash_id} car={car} />
        })}
      </div>
      {isLoading && <Loading className={styles.loading} />}
      <Layer white overview value={hashId} onClose={() => {
        query.delete('hash_id');
        history.replace(`?${query.toString()}`);
      }}>
        <CarsDetail hashId={hashId} />
      </Layer>
      <Layer id="dealer-profile" white overview value={dealerHashId} onClose={() => {
        query.delete('dealer_hash_id');
        history.replace(`?${query.toString()}`);
      }}>
        <DealerProfile hashId={dealerHashId} carHashId={hashId} isVisibleSellingButton={dealerHashId !== null} />
      </Layer>
    </div>
  );
});

export default Cars;
