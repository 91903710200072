import { combineReducers } from 'redux';
import cars, { CarsState } from './cars';
import user, { UserState } from './user';
import dealer, { DealerState } from './dealer';
import loading, { LoadingState } from './loading';
import { connectRouter, RouterState } from 'connected-react-router';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export default combineReducers<Application>({
  cars,
  user,
  dealer,
  router: connectRouter(history),
  loading
});

export interface Application {
  cars: CarsState
  user: UserState;
  router: RouterState;
  dealer: DealerState;
  loading: LoadingState;
}
