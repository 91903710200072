import produce from 'immer';
import { handleActions } from 'redux-actions';
import axios, { AxiosResponse } from 'axios';
import createAsyncSagaAction from '../cores/createAsyncSagaAction';
import { action, PayloadAction } from 'typesafe-actions';
import { createAsyncSagaReducerMap } from '../cores/createAsyncSagaReducerMap';
import { DealerProfile } from '../declaration/DealerProfile';
import { Pagination } from '../declaration/Pagination';
import { uniqBy } from 'lodash';
import { Review } from '../declaration/Review';

export enum DealerTypes {
  getDealerReviews = '@dealer/getDealerReviews',
  getDealerProfile = '@dealer/getDealerProfile',
  getDealerPhotoReviews = '@dealer/getDealerPhotoReviews',
  truncateDealerReviews = '@dealer/truncateDealerReviews',
  truncateDealerProfile = '@dealer/truncateDealerProfile',
}

export interface DealerState {
  stopPagination: boolean;
  dealerProfile: DealerProfile | null;
  dealerReviews: Array<Review>;
  dealerPhotoReviews: Array<Review>;
  dealerPhotoReviewsCount: number | null;
}

export const DealerActions = {
  getDealerReviews: createAsyncSagaAction(DealerTypes.getDealerReviews, (hashId: string, query: URLSearchParams) => {
    query.set('envelope', 'true');
    return axios.get(`/admin/customer_view/users/${hashId}/reviews/`, { params: query });
  }),
  getDealerProfile: createAsyncSagaAction(DealerTypes.getDealerProfile, (hashId: string, query: URLSearchParams) => {
    return axios.get(`/admin/customer_view/users/${hashId}/profile/`, { params: query });
  }),
  getDealerPhotoReviews: createAsyncSagaAction(DealerTypes.getDealerPhotoReviews, (hashId: string) => {
    const query = new URLSearchParams();
    query.set('type', 'photo');
    query.set('envelope', 'true');
    return axios.get(`/admin/customer_view/users/${hashId}/reviews/`, { params: query });
  }),
  truncateDealerReviews: () => action(DealerTypes.truncateDealerReviews),
  truncateDealerProfile: () => action(DealerTypes.truncateDealerProfile),
};

const initialState: DealerState = {
  stopPagination: false,
  dealerProfile: null,
  dealerReviews: [],
  dealerPhotoReviews: [],
  dealerPhotoReviewsCount: null
};

export default handleActions<DealerState, any>(
  {
    [DealerTypes.truncateDealerReviews]: (state) => {
      return produce(state, (draft) => {
        draft.dealerReviews = [];
      });
    },
    [DealerTypes.truncateDealerProfile]: (state) => {
      return produce(state, (draft) => {
        draft.dealerProfile = null;
      });
    },
    ...createAsyncSagaReducerMap(DealerTypes.getDealerReviews, {
      onSuccess: (
        state,
        {
          payload: {
            data: {
              meta: { next },
              results,
            },
          },
        }: PayloadAction<string, AxiosResponse<Pagination<Review>>>
      ) => {
        return produce(state, (draft) => {
          draft.stopPagination = next === null;
          draft.dealerReviews = uniqBy([...state.dealerReviews, ...results], ({ hash_id }) => hash_id);
        });
      },
    }),
    ...createAsyncSagaReducerMap(DealerTypes.getDealerPhotoReviews, {
      onSuccess: (
        state,
        {
          payload: {
            data: {
              meta: { count },
              results,
            }
          },
        }: PayloadAction<string, AxiosResponse<Pagination<Review>>>
      ) => {
        return produce(state, (draft) => {
          draft.dealerPhotoReviews = results;
          draft.dealerPhotoReviewsCount = count;
        });
      },
    }),
    ...createAsyncSagaReducerMap(DealerTypes.getDealerProfile, {
      onSuccess: (state, action: PayloadAction<string, AxiosResponse<DealerProfile>>) => {
        return produce(state, (draft) => {
          draft.dealerProfile = action.payload.data;
        });
      },
    }),
  },
  initialState
);
