import { useEffect } from 'react';

export function usePagination(callback: () => void, target?: HTMLElement) {

  useEffect(() => {
    function onScroll() {
      if (target) {
        if (target.scrollHeight - window.innerHeight * 0.05 <= target.scrollTop + window.innerHeight) {
          callback();
        }
      } else {
        if (document.body.scrollHeight - window.innerHeight * 0.05 <= window.scrollY + window.innerHeight) {
          callback();
        }
      }
    }

    if (target) {
      target.addEventListener('scroll', onScroll);
    } else {
      window.addEventListener('scroll', onScroll);
    }

    return () => {
      if (target) {
        target.removeEventListener('scroll', onScroll);
      } else {
        window.removeEventListener('scroll', onScroll);
      }
    };
  }, [callback]);
}
